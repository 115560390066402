import { matchesRule } from './utils';

class SurveyTargeting {
  constructor(config) {
    this.config = config;
    this.currentUrl = window.location.href;
    this.isVisible = false;
    this.setupListeners();
  }

  setupListeners() {
    window.addEventListener('popstate', this.handleUrlChange.bind(this));
    this.setupSpaUrlChangeDetection();
    this.intervalId = setInterval(this.checkUrlChange.bind(this), 1000);
  }

  setupSpaUrlChangeDetection() {
    let lastUrl = location.href;
    this.observer = new MutationObserver(() => {
      const url = location.href;
      if (url !== lastUrl) {
        lastUrl = url;
        this.handleUrlChange();
      }
    });
    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  checkUrlChange() {
    if (this.currentUrl !== window.location.href) {
      this.handleUrlChange();
    }
  }

  handleUrlChange() {
    this.currentUrl = window.location.href;
    this.evaluateSurveyVisibility();
  }

  evaluateSurveyVisibility() {
    if (this.shouldShowSurvey()) {
      this.showSurvey();
    } else if (this.isVisible && this.config.closeOnUrlChange) {
      this.hideSurvey();
    }
  }

  shouldShowSurvey() {
    return this.config.targetUrls.some(rule => matchesRule(rule, this.currentUrl));
  }

  showSurvey() {
    if (!this.isVisible) {
      this.isVisible = true;
      this.config.onShow();
    }
  }

  hideSurvey() {
    if (this.isVisible) {
      this.isVisible = false;
      this.config.onHide();
    }
  }

  destroy() {
    window.removeEventListener('popstate', this.handleUrlChange);
    this.observer.disconnect();
    clearInterval(this.intervalId);
  }
}

export default SurveyTargeting;