/* eslint-disable react/style-prop-object,react/prop-types */
/** @jsx h */
import * as React from 'preact';
import csat1 from './images/csat-1.svg';
import csat2 from './images/csat-2.svg';
import csat3 from './images/csat-3.svg';
import csat4 from './images/csat-4.svg';
import csat5 from './images/csat-5.svg';

import star from './images/star.svg';
import style from './WebSurveyWidget.css';

import AdditionalQuestionForm from '../../../Response/components/AdditionalQuestionForm/AdditionalQuestionForm';


const { h } = React;

const csatFaces = [csat1, csat2, csat3, csat4, csat5];

const MIN_WINDOW_WIDTH_DESKTOP = 992;
const SURVEY_TYPES = {
  nps: 'nps',
  ces: 'ces',
  csat: 'csat',
  starFive: 'star_five',
  custom: 'custom',
};

const SURVEY_CSAT_TYPES = {
  faces: 'faces',
  numbers: 'numbers',
};

const WebSurveyWidget = (props) => {
  const {
    brand,
    close,
    comment,
    csatFacesNum,
    dark,
    focusTextArea,
    hasPrevious,
    inline,
    hideClose,
    isClosed,
    isSmallQuestionShown,
    isThanking,
    nextQuestion,
    previousQuestion,
    questionValue,
    score,
    setQuestionValue,
    setTextArea,
    showAdditional,
    showSmallQuestion,
    survey,
    surveyCsatType,
    surveyType,
    thankyouTitle,
    answers,
  } = props;
  const dir = ['ar', 'he'].indexOf(survey.locale) > -1 ? 'rtl' : 'ltr';
  const darkClass = dark ? style.pnDark : '';
  const inlineClass = inline ? style.pnInline : '';
  let numbers = [5, 4, 3, 2, 1];
  if (surveyType === 'nps') {
    numbers = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
  } else if (surveyType === SURVEY_TYPES.csat && surveyCsatType === SURVEY_CSAT_TYPES.faces) {
    const numberArrays = [[], [], [5, 1], [5, 3, 1], [5, 4, 2, 1], [5, 4, 3, 2, 1]];
    numbers = numberArrays[csatFacesNum];
  }
  const borderClass = ({ square: style.pnSquare, circle: style.pnCircle })[survey.shape] || '';
  const colorStyle = color => (
    survey.style === 'outline'
      ? `color: ${color} !important; border: 1px solid ${color} !important; background: #fff !important`
      : `background: ${color} !important`
  );
  const onSubmit = (evt) => {
    evt.preventDefault();
    props.sendAnswer({ close: !survey.additionalQuestions?.length, showNext: true });
    return false;
  };
  const thanking = isThanking ? style.pnWebIsThanking : '';
  const closed = isClosed ? style.pnWebIsClosed : '';
  const isSmallScreen = window.innerWidth < MIN_WINDOW_WIDTH_DESKTOP && !inline;
  const mediaClass = isSmallScreen ? style.pnWebTouch : style.pnWebDesktop;
  const commentShown = score !== null && showAdditional === null;
  const questionShown = isSmallQuestionShown ? style.pnQuestionIsShown : '';
  let width = 220;
  if (surveyType === SURVEY_TYPES.nps) {
    width = 490;
  } else if (surveyType === SURVEY_TYPES.csat && surveyCsatType === SURVEY_CSAT_TYPES.faces) {
    width = csatFacesNum * 44;
  }
  width += 'px !important';
  const back = surveyType === SURVEY_TYPES.starFive || (surveyType === SURVEY_TYPES.csat && surveyCsatType === SURVEY_CSAT_TYPES.faces) ? style.noBack : '';
  const thanks = thankyouTitle || survey.messages.thanks;
  const question = survey.customizations.find(c => c.name === 'question' && c.language === survey.locale)?.value?.replace('{brand}', brand)
    || survey.messages.question.replace('{brand}', brand);
  return (
    <div
      id="pnWeb"
      className={`${style.pnWebIsShown} ${thanking} ${closed} ${mediaClass} ${borderClass} ${questionShown} ${darkClass} ${inlineClass}`}
    >
      {isSmallScreen ? (
        <button
          className={style.pnWebToast}
          style={`background: ${survey.topColor} !important;`}
          type="button"
          onClick={showSmallQuestion}
        >
          <div className={style.pnWebToastText}>
            {question}
          </div>
          {!hideClose && (
            <button id="pnWebClose" className={style.pnWebToastClose} type="button" onClick={close}>
              ×
            </button>
          )}
        </button>
      ) : null}
      <div className={style.pnWebSurvey + (commentShown ? ` ${style.pnWebCommentIsShown}` : '') + (showAdditional !== null ? ` ${style.pnWebAdditionalIsShown}` : '')}>
        <div className={style.pnWebSurveyInner}>
          {showAdditional === null ? (
            <>
              <div className={`${style.pnWebStep} ${style.pnWebStepQuestion} ${style.pnWebIsShown}`}>
                <div className={style.pnWebStepInner}>
                  <div className={style.pnWebQuestion}>
                    <div className={style.pnWebColumn} style="max-width: none !important">
                      <div className={`${style.pnWebLabel} ${style.pnWebLabelQuestion}`}>
                        {question}
                      </div>
                    </div>
                    <div className={`${style.pnWebQuestionScore} ${style[surveyType]}`} style={isSmallScreen ? '' : `width: ${width}`}>
                      <div className={style.pnWebColumn}>
                        <div className={`${style.pnWebQuestionScoreNumbers} ${back}`}>
                          {numbers.map((i, idx) => {
                            const min = i === 0 ? style.pnWebQuestionScoreNumberMin : '';
                            const max = i === 10 ? style.pnWebQuestionScoreNumberMax : '';
                            const animStart = (i + 1) * 0.032;
                            let activeClass;
                            if (props.score === null) {
                              activeClass = '';
                            } else if (props.score === i) {
                              activeClass = style.pnWebIsActive;
                            } else {
                              activeClass = style.pnWebIsInactive;
                            }
                            const className = `${style.pnWebBtn} ${borderClass} ${style.pnWebQuestionScoreNumber} ${min} ${max} ${activeClass}`;
                            const styleColor = (surveyType === SURVEY_TYPES.starFive || (surveyType === 'csat' && surveyCsatType === SURVEY_CSAT_TYPES.faces)) ? '' : colorStyle(survey.buttonColor);
                            let html = i.toString();
                            if (surveyType === SURVEY_TYPES.starFive) {
                              html = star;
                            } else if (surveyType === SURVEY_TYPES.csat && surveyCsatType === SURVEY_CSAT_TYPES.faces) {
                              html = csatFaces[i - 1];
                            }
                            return (
                              <button
                                type="button"
                                className={className}
                                style={`animation: ${style.pnWebBtnAnim} 0.325s linear ${animStart}s 1 normal both !important;${styleColor}`}
                                onClick={() => {
                                  props.setScore(i);
                                  props.sendAnswer({ close: false, score: i });
                                  focusTextArea();
                                }}
                              >
                                <div dangerouslySetInnerHTML={{ __html: html }} />
                                {(() => {
                                  if ((surveyType !== SURVEY_TYPES.csat || surveyCsatType !== SURVEY_CSAT_TYPES.faces)) {
                                    if (idx === 0) {
                                      return (
                                        <span
                                          className={`${style.pnWebQuestionScoreLikelihood} ${style.pnWebQuestionScoreLikelihoodRight}`}
                                        >
                                          {survey.messages.top}
                                        </span>
                                      );
                                    }
                                    if (idx === numbers.length - 1) {
                                      return (
                                        <span
                                          className={`${style.pnWebQuestionScoreLikelihood} ${style.pnWebQuestionScoreLikelihoodLeft}`}
                                        >
                                          {survey.messages.bottom}
                                        </span>
                                      );
                                    }
                                  }
                                  return null;
                                })()}
                              </button>
                            );
                          })}
                        </div>
                        {(surveyType !== SURVEY_TYPES.csat || surveyCsatType !== SURVEY_CSAT_TYPES.faces) && (
                          <div className={style.pnWebQuestionScoreLikelihoodDesktop}>
                            <div>{survey.messages.bottom}</div>
                            <div>{survey.messages.top}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${style.pnWebStep} ${style.pnWebStepComment}`}>
                <div className={style.pnWebStepInner}>
                  <div className={style.pnWebColumn}>
                    <form className={style.pnWebComment} acceptCharset="UTF8" method="post" onSubmit={onSubmit}>
                      <div className={`${style.pnWebLabel} ${style.pnWebLabelComment}`}>
                        {survey.messages.tell_us_more.replace('{score}', score)}
                      </div>
                      <textarea
                        placeholder={isSmallScreen ? null : survey.messages.tell_us_more.replace('{score}', score)}
                        className={style.pnWebCommentBox}
                        value={comment}
                        onChange={e => props.setComment(e.target.value)}
                        onFocus={(e) => {
                          e.target.setAttribute('style', `border: 1px solid ${props.survey.buttonColor} !important`);
                        }}
                        onBlur={(e) => {
                          e.target.removeAttribute('style');
                        }}
                        name="comment"
                        ref={t => setTextArea(t)}
                      >
                        {comment}
                      </textarea>
                      <button
                        name="button"
                        type="submit"
                        style={colorStyle(survey.buttonColor)}
                        className={`${style.pnWebCommentSubmit} ${style.pnWebBtn} ${borderClass}`}
                        dir={dir}
                      >
                        {survey.additionalQuestions?.length > 0 ? survey.messages.nextQuestion : survey.messages.submit}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={style.additionalQuestionsWrapper}>
              <AdditionalQuestionForm
                additionalQuestions={survey.additionalQuestions}
                values={Object.entries(answers).reduce((acc, [i, a]) => ({ ...acc, [survey.additionalQuestions[i]._id]: a }), {})}
                question={survey.additionalQuestions[showAdditional]}
                buttonColor={survey.buttonColor}
                messages={survey.messages}
                value={questionValue}
                setValue={setQuestionValue}
                onSubmit={nextQuestion}
                onPrevious={previousQuestion}
                dir={dir}
                hasNext={survey.additionalQuestions?.length > showAdditional + 1}
                hasPrevious={hasPrevious}
                dark={dark}
              />
            </div>
          )}
          <div className={`${style.pnWebStep} ${style.pnWebStepThanks}`}>
            <div className={style.pnWebStepInner}>
              <div className={style.pnWebColumn}>
                <div className={style.pnWebThanksText}>
                  {thanks}
                </div>
              </div>
            </div>
          </div>
          <a
            className={style.pnWebSurveyPowered}
            rel="noopener noreferrer"
            target="_blank"
            href={`https://www.promoter.ninja?utm_source=promoter-ninja&utm_medium=web&utm_campaign=survey&utm_content=${survey.token}`}
          >
            {survey.messages.powered.replace('{promoter_ninja}', 'Promoter Ninja')}
          </a>
        </div>
        {!hideClose && (
          <button id="pnWebClose" className={style.pnWebSurveyClose} type="button" onClick={close}>
            ×
          </button>
        )}
      </div>
    </div>
  );
};

export default WebSurveyWidget;
