/* eslint-disable react/prop-types */
import OptionButtonGroupContext from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/OptionButtonGroupContext';
import * as React from 'preact';
import {
  useRef, useMemo, useState, useEffect,
} from 'preact/hooks';

import styles from './OptionButton.css';

// eslint-disable-next-line no-unused-vars
const { h } = React;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const OptionButtonGroup = (props) => {
  const {
    // private
    actions,
    children,
    defaultValue,
    name,
    onChange,
    value: valueProp,
    multiple,
    ...other
  } = props;
  const prevMultiple = usePrevious(multiple);
  const prevName = usePrevious(name);
  const [value, setValue] = useState(valueProp || defaultValue || multiple ? [] : null);
  useEffect(() => {
    setValue(valueProp || defaultValue || value);
  }, [valueProp, defaultValue, multiple]);
  useEffect(() => {
    if (prevName === name && prevMultiple !== multiple) {
      setValue(multiple ? [] : null);
    }
  }, [multiple]);
  const contextValue = useMemo(
    () => ({
      name,
      onChange(event) {
        event.preventDefault();
        let newValue;
        if (multiple) {
          newValue = event.target.checked ? [...value, event.target.value] : value.filter(v => v !== event.target.value);
        } else {
          newValue = event.target.value;
        }
        setValue(newValue);

        if (onChange) {
          onChange(event, newValue);
        }
      },
      value,
      multiple,
    }),
    [name, onChange, setValue, value],
  );

  return (
    <OptionButtonGroupContext.Provider value={contextValue}>
      <div className={styles.group} {...other}>
        {children}
      </div>
    </OptionButtonGroupContext.Provider>
  );
};

export default OptionButtonGroup;
