/** @jsx h */
import * as cookies from 'browser-cookies';
/* eslint-disable react/style-prop-object,react/prop-types */
import cleanslate from 'cleanslate/cleanslate.css';
import * as React from 'preact';
import { useEffect } from 'preact/hooks';
import hashCode from '../../../../util/hashCode';
import { Logger, LogLevel } from '../../../../util/Logger';
import toFormURLEncoded from '../../../../util/toFormURLEncoded';
import WebSurveyWidget from './WebSurveyWidget';
import SurveyTargeting from './SurveyTargeting';

const { h } = React;

const NO_CONTENT = 204;
const METHOD_NOT_ALLOWED = 405;
const dev = process.env.NODE_ENV === 'development';
const test = window && !!window.location.search.match(/promoterninja=test/i);
const prod = process.env.NODE_ENV === 'production';
const ONE_YEAR = 31556926;
const setCookie = (name, payload = {}, ts = new Date()) => {
  cookies.set(name, JSON.stringify([ts.getTime(), payload]), {
    expires: ONE_YEAR,
    secure: prod,
    httpOnly: false,
    path: '/',
  });
};
const getCookie = name => {
  const c = JSON.parse(cookies.get(name));
  if (c && c.length === 2) {
    return c;
  }
  return [];
};
const rootUrl = dev ? 'http://localhost:8080' : 'https://www.promoter.ninja';
const COOKIE_LAST_RESPONSE = '_pnlrt';
const COOKIE_FIRST_SEEN = '_pnfst';
const COOKIE_LAST_SEEN = '_pnlst';
const COOKIE_THROTTLED = '_pnthd';

// Define a mapping of input property names (in various formats) to their standardized output names.
const propertyMapping = {
  locale: 'locale',
  language: 'locale', // 'language' is treated the same as 'locale'
  brand: 'brand',
  image: 'image',
  logo: 'logoUrl', // Both 'logo' and 'logourl' map to 'logoUrl'
  logourl: 'logoUrl',
  questionproductname: 'brand', // Specific case where a product name is treated as a brand
  question: 'question',
  emailsubject: 'email_subject',
  emailintro: 'intro',
  emailfooter: 'footer',
  emailcolor: 'color',
  fromname: 'fromName', // Both 'fromname' and 'emailfromname' map to 'fromName'
  emailfromname: 'fromName',
  emailreplyto: 'replyTo',
  topcolor: 'topcolor',
  buttoncolor: 'buttoncolor',
};

const mapKey = key => propertyMapping[key.replace(/[_ ]/g, '').toLowerCase()];

const isEmpty = specialProperties => Object.keys(specialProperties).length === 0;

const pickSpecial = inputProps => {
  const specialProperties = Object.keys(inputProps).reduce((accumulator, key) => {
    const mappedKey = mapKey(key);
    return mappedKey ? { ...accumulator, [mappedKey]: inputProps[key] } : accumulator;
  }, {});

  return isEmpty(specialProperties) ? {} : { properties: specialProperties };
};

const script =
  document.currentScript ||
  /* Polyfill */ Array.prototype.slice.call(document.getElementsByTagName('script')).pop();
const scriptUrl = new URL(script.getAttribute('src'), window.location);
const scriptParams = Object.fromEntries(scriptUrl.searchParams);
const scriptVersion = scriptParams.v;

function apiCall(endpoint, bodyObj, method = 'post') {
  if (!window || !window.fetch) {
    return Promise.reject(new Error('Browser does not support fetch'));
  }

  const headers = bodyObj ? { 'Content-Type': 'application/x-www-form-urlencoded' } : {};
  const params = toFormURLEncoded({ ...bodyObj, sv: scriptVersion });
  const toJson = response =>
    response.status === NO_CONTENT
      ? { json: null, response }
      : response.json().then(json => ({ json, response }));
  return window
    .fetch(`${rootUrl}/api/public/${endpoint}${method === 'get' ? `?${params}` : ''}`, {
      headers,
      method,
      body: method.toLowerCase() === 'get' ? undefined : params,
    })
    .catch(e => {
      console.error(e);
      throw e;
    })
    .then(response => toJson(response))
    .then(({ json, response }) => {
      if (!response.ok) {
        const err = new Error(json.message || 'Error fetching API response');
        err.name = response.status;
        throw err;
      }
      return json;
    });
}

const defaultState = {
  score: null,
  comment: null,
  survey: null,
  person: null,
  isThanking: false,
  isClosed: false,
  isSmallQuestionShown: false,
  showAdditional: null,
  answeredIdxs: [],
  answers: {},
  selectedDrivers: null,
  isVisible: false,
};

class WebSurveyWidgetContainer extends React.Component {
  constructor(props) {
    super(props);
    this.logger = new Logger('Promoter Ninja');
    Logger.setLevel(dev || test ? LogLevel.Debug : LogLevel.Warning);
    this.state = defaultState;
    this.surveyTargeting = null;
  }

  componentDidMount() {
    const varName = script.getAttribute('data-v') || 'promoterNinja';
    while (window[varName] && window[varName].length > 0) {
      const pn = window[varName];
      const [fun, ...callArgs] = pn.shift();
      const callableFuncs = { survey: this.survey };
      callableFuncs[fun].call(this, ...callArgs);
    }
    this.loadedAt = new Date().getTime();
    window[varName] = {
      survey: this.survey.bind(this),
      loadedAt: this.loadedAt,
    };

    // Initialize SurveyTargeting
    if (this.state.survey && this.state.survey.targetUrls) {
      this.initializeSurveyTargeting();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevState.survey && this.state.survey) ||
      (prevState.survey && !prevState.survey.targetUrls && this.state.survey.targetUrls)
    ) {
      this.initializeSurveyTargeting();
    }
  }

  componentWillUnmount() {
    if (this.surveyTargeting) {
      this.surveyTargeting.destroy();
    }
  }

  initializeSurveyTargeting() {
    if (this.surveyTargeting) {
      this.surveyTargeting.destroy();
    }

    this.surveyTargeting = new SurveyTargeting({
      targetUrls: this.state.survey.targetUrls,
      closeOnUrlChange: this.state.survey.closeOnUrlChange,
      onShow: () => {
        this.setState({ isVisible: true });
      },
      onHide: () => {
        this.setState({ isVisible: false });
        this.close();
      },
    });
  }

  setScore(score) {
    this.setState({ score });
  }

  setComment(comment) {
    this.setState({ comment });
  }

  setTextArea(textarea) {
    this.textarea = textarea;
  }

  reset() {
    this.setState(defaultState);
  }

  close() {
    const { survey } = this.state;
    const [date] = getCookie(COOKIE_LAST_SEEN);
    setCookie(COOKIE_LAST_SEEN, -1, new Date(date));
    this.setState({ isClosed: true });
    setTimeout(this.reset.bind(this), 600);
    return apiCall(`response/${survey.token}/close`, {}, 'put').catch(() => {
      this.logger.warning('Error sending answer');
      this.close();
    });
  }

  showSmallQuestion() {
    this.setState({ isSmallQuestionShown: true });
  }

  sendAnswer({ close = true, score: scoreIn, showNext = false } = {}) {
    const {
      survey,
      comment,
      options: { sendEmailAfter, properties, url },
    } = this.state;
    const score = typeof scoreIn === 'undefined' ? this.state.score : scoreIn;
    if (close) {
      this.setState({ isThanking: true });
      setTimeout(this.close.bind(this), 2000);
    }
    const [date] = getCookie(COOKIE_LAST_SEEN);
    setCookie(COOKIE_LAST_SEEN, 0, new Date(date));
    return apiCall(
      survey.token ? `response/${survey.token}` : `survey/${survey.webId}/response`,
      {
        score: `${score}`,
        comment,
        channel: 'web',
        sendEmailAfter,
        properties,
        url,
      },
      survey.token ? 'put' : 'post',
    )
      .then(res => {
        if (!survey.token && res.id) {
          this.setState({ survey: { ...survey, token: res.id } });
        }
        if (showNext) {
          if (typeof res.nextQuestion === 'number' && res.nextQuestion >= 0) {
            this.setState({ showAdditional: res.nextQuestion });
          }
        }
        if (res.thankyouTitle) {
          this.setState({ thankyouTitle: res.thankyouTitle });
        }
        if (res.followUpQuestion) {
          this.setState({ followUpQuestion: res.followUpQuestion });
        }
        if (res.drivers) {
          this.setState({ drivers: res.drivers });
        }
        if (res.followupStrategy) {
          this.setState({ followupStrategy: res.followupStrategy });
        }
        setCookie(COOKIE_LAST_RESPONSE);
      })
      .catch(() => {
        this.logger.warning('Error sending answer');
        this.close();
      });
  }

  surveyThrottled(email, nextSurveyAt) {
    const d = new Date(nextSurveyAt * 1000);
    setCookie(COOKIE_THROTTLED, { email: hashCode(email) }, d);
  }

  surveySent(recurringPeriod) {
    const [date] = getCookie(COOKIE_FIRST_SEEN);
    const [lastSeenAt, n = 0] = getCookie(COOKIE_LAST_SEEN);
    const now = new Date().getTime();
    if (!date) {
      setCookie(COOKIE_FIRST_SEEN);
    }
    setCookie(COOKIE_LAST_SEEN, now > lastSeenAt + recurringPeriod * 1000 ? 1 : n + 1);
  }

  shouldSeeSurvey(email) {
    const [throttledUntil, { email: throttledEmail } = {}] = getCookie(COOKIE_THROTTLED);
    const now = new Date().getTime();
    if (throttledUntil && now < throttledUntil && hashCode(email) === throttledEmail) {
      this.logger.info(
        `Survey not showing. Will be eligible after ${new Date(throttledUntil).toLocaleString()}.`,
      );
      return test;
    }
    return true;
  }

  async survey(params) {
    const {
      surveyId,
      userId,
      email,
      name,
      createdAt,
      initialDelay,
      recurringPeriod,
      recurringAttempts = 1,
      properties = {},
      sampling,
      sendEmailAfter,
      dark = false,
      force,
      folderId,
      inline = false,
      hideClose = false,
      cache = !params.email,
    } = params;
    const cacheOptions = {
      createdAt,
      recurringAttempts,
      recurringPeriod,
      initialDelay,
      force,
      folderId,
      lastSeen: getCookie(COOKIE_LAST_SEEN)[0],
      ...pickSpecial(properties),
    };
    const options = {
      ...cacheOptions,
      url: window.location.href,
      sampling,
      sendEmailAfter,
      properties: {
        ...properties,
        name,
        email,
        channel: 'web',
      },
    };
    if (test) {
      options.promoterNinja = 'test';
    }
    if (email === null) {
      this.logger.warning('Email can not be null');
      this.setState({ survey: null });
      return;
    }
    let createdAtDate = new Date(createdAt).getTime();
    if (createdAtDate < new Date('1972-01-01').getTime()) {
      // Before 1972... A date in unix format was passed?
      createdAtDate = new Date(createdAt * 1000);
    }
    if (
      force ||
      this.shouldSeeSurvey(email, createdAtDate, initialDelay, recurringPeriod, recurringAttempts)
    ) {
      try {
        const webId = surveyId || userId;
        const { status, response, message } = await apiCall(
          `web/${webId}/people`,
          cache ? cacheOptions : options,
          cache ? 'get' : 'post',
        );
        if (
          status === 'ok' &&
          (response.surveyType !== 'custom' || typeof response.nextQuestion === 'number')
        ) {
          setTimeout(
            () => {
              this.setState({
                survey: {
                  ...response,
                  webId,
                },
                showAdditional: response.surveyType === 'custom' ? response.nextQuestion : null,
                score: response.surveyType === 'custom' ? 0 : null,
                options,
                dark,
                inline,
                hideClose,
                locale: properties.language || properties.locale || response.locale,
              });
              this.surveySent(recurringPeriod);
              setTimeout(() => {
                try {
                  const { x, y } = document.getElementById('pnWebClose').getBoundingClientRect();
                  const elementFromPoint = document.elementFromPoint(x + 5, y + 5);
                  const MY_Z_INDEX = 2147483640;
                  const showAbove = (elem, level = 0) => {
                    if (!elem || level > 100) {
                      return;
                    }
                    if (
                      window.getComputedStyle(elem).zIndex >= MY_Z_INDEX &&
                      elem.getAttribute('id') !== 'pnWebClose'
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      elem.style.zIndex = MY_Z_INDEX - 1;
                    }
                    showAbove(elem.parentElement, level + 1);
                  };
                  showAbove(elementFromPoint);
                } catch (e) {
                  // Ignore errors trying to display on top
                }
              }, 250);
            },
            (response.timeDelayOnPage || 0) * 1000,
          );
        } else if (status === 'aborted') {
          this.surveyThrottled(email, response.next_survey_at);
          this.logger.info(`Survey not showing: ${response.reason}`);
        } else if (status === METHOD_NOT_ALLOWED) {
          this.logger.warning(message);
        }
      } catch (e) {
        this.logger.warning('Error retrieving survey');
        this.setState({ survey: null });
      }
    }
  }

  focusTextArea() {
    if (this.textarea) {
      this.textarea.focus();
    }
  }

  handleQuestionAnswer(value) {
    this.setState(({ answers }) => ({
      answers: {
        ...answers,
        [this.state.showAdditional]: value,
      },
    }));
  }

  render() {
    const {
      survey,
      person,
      score,
      comment,
      isThanking,
      isClosed,
      isSmallQuestionShown,
      dark,
      inline,
      hideClose,
      thankyouTitle,
      sendEmailAfter,
      properties,
      url,
      followUpQuestion,
      followupStrategy,
      drivers,
      selectedDrivers,
      isVisible,
    } = this.state;
    if (!survey || !isVisible) {
      return null;
    }
    const brand = (person && person.brand) || survey.brand;
    const { surveyType = 'nps', surveyCsatType = 'faces', csatFacesNum = 5 } = survey;
    const handleNext = async (questionId, value) => {
      let { token } = survey;
      if (questionId) {
        if (!survey.token) {
          const res = await apiCall(
            `survey/${survey.webId}/response`,
            {
              score: `${score}`,
              comment,
              channel: 'web',
              sendEmailAfter,
              properties,
              url,
            },
            'post',
          );
          if (res.id) {
            token = res.id;
            this.setState({ survey: { ...survey, token: res.id } });
          }
        }
        const { nextQuestion } = await apiCall(
          `response/${token}/question/${questionId}`,
          { value },
          'put',
        );
        this.setState(({ answeredIdxs }) => ({
          answeredIdxs: [...answeredIdxs, this.state.showAdditional],
        }));
        if (typeof nextQuestion === 'number') {
          this.setState({ showAdditional: nextQuestion });
        } else {
          this.setState({
            showAdditional: null,
            isThanking: true,
          });
          setTimeout(this.close.bind(this), 2000);
        }
      }
    };
    const handlePrevious = () => {
      if (this.state.showAdditional > 0) {
        this.setState(({ answeredIdxs: prevIds }) => {
          const answeredIdxs = [...prevIds];
          const showAdditional = answeredIdxs.pop();
          return { showAdditional, answeredIdxs };
        });
      }
    };
    const toggleDriver = driver => () => {
      this.setState(prevState => {
        const newSelectedDrivers = prevState.selectedDrivers?.includes(driver._id)
          ? prevState.selectedDrivers.filter(d => d !== driver._id)
          : [...(prevState.selectedDrivers || []), driver._id];
        return { selectedDrivers: newSelectedDrivers };
      });
    };
    useEffect(() => {
      if (selectedDrivers && survey.token) {
        apiCall(
          `response/${survey.token}`,
          {
            drivers: selectedDrivers,
          },
          'put',
        );
      }
    }, [selectedDrivers, survey.token]);
    return (
      <div className={cleanslate.cleanslate}>
        <WebSurveyWidget
          person={person}
          survey={survey}
          surveyType={surveyType}
          surveyCsatType={surveyCsatType}
          csatFacesNum={csatFacesNum}
          brand={brand}
          score={score}
          comment={comment}
          setScore={this.setScore.bind(this)}
          setComment={this.setComment.bind(this)}
          close={this.close.bind(this)}
          sendAnswer={this.sendAnswer.bind(this)}
          isThanking={isThanking}
          isClosed={isClosed}
          isSmallQuestionShown={isSmallQuestionShown}
          showSmallQuestion={this.showSmallQuestion.bind(this)}
          setTextArea={this.setTextArea.bind(this)}
          focusTextArea={this.focusTextArea.bind(this)}
          dark={dark}
          inline={inline}
          hideClose={hideClose}
          thankyouTitle={thankyouTitle}
          showAdditional={this.state.showAdditional}
          nextQuestion={handleNext}
          previousQuestion={handlePrevious}
          answers={this.state.answers}
          questionValue={this.state.answers[this.state.showAdditional]}
          setQuestionValue={this.handleQuestionAnswer.bind(this)}
          hasPrevious={this.state.answeredIdxs.length > 0}
          followUpQuestion={followUpQuestion}
          followupStrategy={followupStrategy}
          drivers={drivers}
          toggleDriver={toggleDriver}
        />
      </div>
    );
  }
}

export default WebSurveyWidgetContainer;
