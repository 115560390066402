/* eslint-disable react/prop-types */
import OptionButton from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/OptionButton';
import OptionButtonGroup from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/OptionButtonGroup';
import ToggleButton from 'client/modules/Response/components/AdditionalQuestionForm/ToggleButton';
import ToggleButtonGroup from 'client/modules/Response/components/AdditionalQuestionForm/ToggleButtonGroup';
import FreeResponseTypes from 'client/modules/Surveys/pages/CustomizeSurvey/FreeResponseTypes';
import {
  freeResponse, scale, selectMany, selectOne,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import lightenHex from 'client/util/lightenHex';
import replacePlaceholders from 'client/util/replacePlaceholders';
import * as React from 'preact';
import styles from './AdditionalQuestionForm.css';

// eslint-disable-next-line no-unused-vars
const { h } = React;

const numberArrays = [[], [], [1, 5], [1, 3, 5], [1, 2, 4, 5], [1, 2, 3, 4, 5]];

const getValues = (range, style) => {
  if (style === 'faces') {
    return numberArrays[range];
  }
  return [...Array(range).keys()].map(i => i + (range < 11 ? 1 : 0));
};
const AdditionalQuestionForm = ({
  question, dir, hasPrevious, hasNext, buttonColor, onSubmit = () => {}, onPrevious = () => {}, value = null, setValue, messages,
  dark, disabled, stopEditingMessage, additionalQuestions, values,
}) => {
  const handleToggleButtonChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(question._id, value);
  };

  if (!question) {
    return null;
  }
  const style = question.style || 'numerical';
  const highlightColor = dark ? lightenHex(buttonColor, -0.8) : lightenHex(buttonColor, 0.95);
  const range = getValues(question.range, style);
  const left = range[0];
  const right = range[range.length - 1];
  const replacements = additionalQuestions?.reduce((acc, q, i) => {
    acc[`answer:${q._id}`] = q.optionsAttributes?.find(o => o._id === values[q._id])?.text || values[q._id] || '______';
    return acc;
  }, {});
  return (
    <div
      className={styles.root}
      style={{
        '--buttonBackground': dark ? '#333' : '#fff',
        '--textColor': dark ? '#fff' : '#333',
      }}
    >
      {!question.intro || (
        <p className={styles.intro} dir={dir}>
          {replacePlaceholders(question.intro, replacements)}
        </p>
      )}
      <p className={styles.question} dir={dir}>
        {replacePlaceholders(question.text, replacements)}
      </p>
      {!question.description || (
        <p className={styles.description} dir={dir}>
          {replacePlaceholders(question.description, replacements)}
        </p>
      )}
      <form
        className={styles.form}
        onSubmit={handleSubmit}
      >
        {/* eslint-disable jsx-a11y/no-autofocus */}
        {question.type === freeResponse && (
          {
            [FreeResponseTypes.date]: (
              <div className={styles.input}>
                <input
                  className={styles.free}
                  type="date"
                  name="date"
                  id="date"
                  onChange={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.phone]: (
              <div className={styles.input}>
                <input
                  className={styles.free}
                  type="tel"
                  pattern="\+?[0-9]+"
                  name="phone"
                  id="phone"
                  onChange={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.email]: (
              <div className={styles.input}>
                <input
                  className={styles.free}
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.number]: (
              <div className={styles.input}>
                <input
                  className={styles.free}
                  type="number"
                  name="number"
                  id="number"
                  onChange={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.shortText]: (
              <div className={styles.input}>
                <input
                  className={styles.free}
                  type="text"
                  name="text"
                  id="text"
                  onChange={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
          }[question.freeResponseType] || (
            <div className={styles.input}>
              <textarea
                className={styles.free}
                dir={dir}
                name="comment"
                id="comment"
                rows="5"
                onChange={handleChange}
                value={value}
                autoFocus
              />
            </div>
          )
        )}
        {/* eslint-enable jsx-a11y/no-autofocus */}
        {question.type === scale && (
          <div className={`${styles.scaleWrapper} ${style === 'numerical' ? styles.numerical : ''}`}>
            <div className={styles.scale}>
              <ToggleButtonGroup
                key={`${question.range}-${style}`}
                value={value}
                onChange={handleToggleButtonChange}
                exclusive
                className={`${styles.toggleButtonGroup} ${typeof value === 'number' ? styles.hasValue : ''} ${style === 'numerical' ? '' : styles.iconButtonGroup}`}
                color={buttonColor}
                highlightColor={highlightColor}
              >
                {range.map((buttonValue, index) => (
                  <ToggleButton value={buttonValue} style={style} index={index}>
                    {buttonValue === left && !!question.scaleMinLabel && (
                      <span className={styles.scaleLabel}>
                    &nbsp;&mdash;&nbsp;
                        {question.scaleMinLabel}
                      </span>
                    )}
                    {buttonValue === right && !!question.scaleMaxLabel && (
                      <span className={styles.scaleLabel}>
                    &nbsp;&mdash;&nbsp;
                        {question.scaleMaxLabel}
                      </span>
                    )}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            {!!(question.scaleMinLabel || question.scaleMaxLabel) && (
              <div className={styles.scaleLabelBelow}>
                <span>{question.scaleMinLabel}</span>
                <span>{question.scaleMaxLabel}</span>
              </div>
            )}
          </div>
        )}

        {question.type === selectOne && (
          <>
            {!question.description && (
              <p className={styles.helper}>
                {messages.selectOne}
              </p>
            )}
            <OptionButtonGroup
              name={question._id}
              value={value}
              onChange={handleToggleButtonChange}
            >
              {question.optionsAttributes?.map(option => (
                <OptionButton
                  value={option._id}
                  label={option.text}
                  color={buttonColor}
                  highlightColor={highlightColor}
                />
              ))}
            </OptionButtonGroup>
          </>
        )}

        {question.type === selectMany && (
          <>
            <p color="muted.main" className={styles.helper}>
              {messages.selectMany}
            </p>
            <OptionButtonGroup
              multiple
              name={question._id}
              value={value}
              onChange={handleToggleButtonChange}
            >
              {question.optionsAttributes?.map(option => (
                <OptionButton
                  value={option._id}
                  label={option.text}
                  color={buttonColor}
                  highlightColor={highlightColor}
                />
              ))}
            </OptionButtonGroup>
          </>
        )}
        {disabled ? (
          <button
            title={stopEditingMessage}
            disabled
            color="inherit"
            type="submit"
            className={styles.outlinedButton}
          >
            {question.buttonLabel || (hasNext ? messages.nextQuestion : messages.done)}
          </button>
        ) : (
          <button
            type="submit"
            className={styles.outlinedButton}
          >
            {question.buttonLabel || (hasNext ? messages.nextQuestion : messages.done)}
          </button>
        )}

      </form>
      {hasPrevious && (
        <button
          disabled={disabled}
          type="button"
          onClick={onPrevious}
          color="inherit"
          className={styles.textButton}
        >
          {messages.previousQuestion}
        </button>
      )}
    </div>
  );
};

export default AdditionalQuestionForm;
