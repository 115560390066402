/* eslint-disable react/prop-types */
import useOptionButtonGroup from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/useOptionButtonGroup';
import lightenHex from 'client/util/lightenHex';
import * as React from 'preact';
import styles from './OptionButton.css';

// eslint-disable-next-line no-unused-vars
const { h } = React;

const OptionButton = ({
  color = '#333', value, label = false, highlightColor = lightenHex(color, 0.95),
}) => {
  const group = useOptionButtonGroup();
  const checked = typeof group?.value === 'string' ? group?.value === value : group.value?.includes(value);
  return (
    <label
      htmlFor={`o-${group.name}-${value}`}
      className={styles.root}
      style={{ '--borderColor': checked ? color : '#ccc', ...(checked ? { '--backgroundColor': highlightColor } : {}) }}
    >
      <span className={styles.inputWrapper}>
        <input
          id={`o-${group.name}-${value}`}
          className={styles.input}
          type={group.multiple ? 'checkbox' : 'radio'}
          checked={checked}
          value={value}
          onChange={group?.onChange}
        />
        {!!checked && (
          <svg style={{ color }} className={styles.icon} focusable="false" aria-hidden viewBox="0 0 24 24">
            <path color={color} d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
          </svg>
        )}
      </span>
      <span className={styles.label}>{label}</span>
    </label>
  );
};

export default OptionButton;
